<template>
    <path d="m248 0c-137 0-248 111.1-248 248 0 137 111 248 248 248s248-111 248-248c0-136.9-111-248-248-248zm0 448c-110.5 0-200-89.4-200-200 0-110.5 89.5-200 200-200s200 89.5 200 200-89.4 200-200 200zm107.2-255.2c0 67.1-72.4 68.1-72.4 92.9v6.3c0 6.6-5.4 12-12 12h-45.6c-6.6 0-12-5.4-12-12v-8.7c0-35.7 27.1-50 47.6-61.5 17.6-9.8 28.3-16.5 28.3-29.6 0-17.2-22-28.7-39.8-28.7-23.2 0-33.9 11-48.9 30-4.1 5.1-11.5 6.1-16.7 2.1l-27.8-21.1c-5.1-3.9-6.3-11.1-2.6-16.4 23.6-34.7 53.7-54.2 100.6-54.2 49 0.1 101.3 38.4 101.3 88.9zm-65.2 167.2c0 23.2-18.8 42-42 42s-42-18.8-42-42 18.8-42 42-42 42 18.8 42 42z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 496, height: 496 });
    },
    emits: ['dimensions'],
};
</script>
